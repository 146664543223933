<template>
  <div class="m-image-gall">
    <r-image
      ratio="kano"
      rounded="kurza"
      :isLoading="false"
      :isFill="false"
      :url="items?.url ? items?.url : items"
    />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: () => 1
    }
  },
  mounted() {
    window.onpopstate = function (events) {
      const event = new Event('click');
      const dropdownEl = document.querySelector('.rir-modal__content-rir-icon_close');
      if (dropdownEl) {
        dropdownEl.addEventListener('click', () => function (es) {
        });
        dropdownEl.dispatchEvent(event);
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.m-image-gall {
  margin-left: 10%;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  max-width: 80%;
}

@media (max-width: 599px) {
  .m-image-gall {
    min-width: 90vw;
  }
}

@supports not (aspect-ratio: 16/9) {
  ::v-deep .rir-gallery {
    height: 34vh;
    padding-top: 50%;
  }
}
</style>
