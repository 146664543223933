<template>
  <div
    :class="['r-map', { 'r-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div
      v-if="search"
      class="r-map__search"
    >
      <r-input
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
      >
        <template #after>
          <r-icon
            icon="search"
            fill="pianist"
          />
        </template>
      </r-input>
    </div>
    <yandex-map

      v-if="isShow && isYmapsReady"
      :coords="center || $cityCenter"
      :zoom.sync="mapZoom"
      @click="onMapClick"
      @dblclick="onMapDblClick"
      :map-type="mapType"
      :options="mapOptions"
      :behaviors="['drag', 'scrollZoom', 'multiTouch']"
      :controls="mapControls"
      @map-was-initialized="onMapInit"
      :cluster-options="clusterOptions"
      @contextmenu="onRightClick"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
      :balloon-component="balloonComponent"
    >
      <slot />
    </yandex-map>
    <div class="r-map__legend flex align-items-center">
      <div class="flex align-items-center mr-4">
        <div
          class="bulb mr-2"
          style="background: var(--rir-rocky)"
        />
        <div class="briscola opacity-72">
          Свободные
        </div>
      </div>
      <div class="flex align-items-center">
        <div
          class="bulb mr-2"
          style="background: var(--rir-fargo)"
        />
        <div class="briscola opacity-72">
          Занятые
        </div>
      </div>
    </div>
    <div
      v-show="control"
      class="r-map__controls"
    >
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        v-if="collapseBtn"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        icon-size="20"
        @click="onCollapseMap"
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        icon="add"
        class="amelie"
        fill="rocky"
        icon-size="20"
        @click="onZoom(1)"
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        @click="onLocation"
      />
    </div>
  </div>
</template>

<script>
import BalloonCard from './BalloonCard.vue';
import DropDownMapButton from './DropDownMapButton.vue';
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';

export default {
  name: 'RirMap',
  components: {
    BalloonCard,
    DropDownMapButton,
    loadYmap
  },
  props: {
    balloonComponent: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, null],
      default: null
    },
    control: {
      type: Boolean,
      default: true
    },
    controls: {
      type: Array,
      default: () => []
    },
    showType: {
      type: Boolean,
      default: false
    },
    showTraffic: {
      type: Boolean,
      default: false
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: false
    },
    center: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 12
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    minZoom: {
      type: Number,
      default: 10
    },

    dblclickTimeout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      mapViewTypes: [
        { id: 'map', active: true, value: 'Схема' },
        { id: 'satellite', active: false, value: 'Спутник' },
        { id: 'hybrid', active: false, value: 'Гибрид' }
      ],
      mapType: 'map',
      isShow: true,
      mapControls: this.controls, // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        // restrictMapArea: [
        //   [54.982611, 43.223491],
        //   [54.817007, 43.482356]
        // ],
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },

      clusterOptions: {
        // Название свойства = название кластера (к которому будет применен данный стиль)
        main: {
          hideIconOnBalloonOpen: false,
          clusterize: true,
          clusterHasHint: true,
          //   clusterDisableClickZoom: true,
          clusterOpenBalloonOnClick: false,

          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ],
          clusterIconPieChartRadius: 30,
          // Радиус центральной части макета.
          clusterIconPieChartCoreRadius: 17,
          // Ширина линий-разделителей секторов и внешней обводки диаграммы.
          clusterIconPieChartStrokeWidth: 4,
          clusterIconLayout: 'default#pieChart'
        },
        orgs: {
          clusterOpenBalloonOnClick: false,
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        }
      },

      mapZoom: this.zoom,
      mapInstanse: null,
      isCollapsed: true,
      timeout: null,
      isYmapsReady: false
    };
  },

  watch: {
    showTraffic(val) {
      const isTrafficShown = !!this.mapInstanse.controls.get('trafficControl').isTrafficShown();

      // если не включен контрол трафика
      if (!this.mapInstanse.controls.get('trafficControl')) return;

      if (val) {
        if (isTrafficShown) return;
        this.mapInstanse.controls.get('trafficControl').showTraffic();
      } else {
        this.mapInstanse.controls.get('trafficControl').hideTraffic();
      }
    }
  },

  async mounted() {
    // настройки карты
    const settings = {
      coordorder: 'latlong'
    };

    !window.ymaps && (await loadYmap(settings));
    this.isYmapsReady = true;
  },

  beforeDestroy() {
    this.mapInstanse && this.mapInstanse.destroy();
  },

  methods: {
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
      this.$nextTick(() => {
        this.mapInstanse.container.fitToViewport();
      });
    },

    onChangeView(obj) {
      // Не хочет менять подложку без выключения
      this.isShow = false;
      this.mapType = obj.id;
      this.mapViewTypes = this.mapViewTypes.map(el => ({ ...el, active: el.id === obj.id }));
      this.$nextTick(() => {
        this.isShow = true;
      });
    },

    onMapInit(e) {
      // console.log('mapInit | objects - ', e.geoObjects.getLength(), e.geoObjects);
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.mapInstanse.options.set('openBalloonOnClick', false);
      // console.log('map', e);
    },

    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },
    onLocation() {
      // console.log('length', this.mapInstanse.geoObjects.getLength());
      // this.mapInstanse.geoObjects.each(function(collection) {
      //   console.log('collectionEach', collection.each);
      //   const checkMarker = marker => {
      //     const markerId = marker.properties.get('markerId');
      //   };
      //   collection.each(checkMarker);
      // });
      // this.mapInstanse.geoObjects.each(function(geoObject) {
      //   // if (geoObject.properties.get('id') == 'some id') {
      //   // return false;
      //   // }
      // });
      //   console.log(
      //     'OM',
      //     this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.getById('1263')
      //   );
      // this.mapInstanse.geoObjects
      //   .get(0)
      //   .clusters.state.set(
      //     'activeObject',
      //     this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.getById('1263')
      //   );
      //   console.log('iterator', this.mapInstanse.geoObjects.getIterator().getNext());
      //   console.log(
      //     '1269',
      //     this.mapInstanse.geoObjects.get(0).clusters._objectManager.objects._objectsById['1263']
      //   );
      //   // Открыть balloon с id = 1386
      //   this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.balloon.open('1386');
      //   this.mapInstanse.container.fitToViewport();
      //         <BalloonCard
      //     slot="balloon"
      //     :title="marker.title"
      //     :address="marker.address"
      //     :img="marker.img"
      //     :data-id="marker.uid"
      //   >
      //   console.log('objects', this.mapInstance.geoObjects.get(0).get(n).balloon.open());
      //   this.mapInstanse.objects.balloon.open(1);
      //   this.mapInstanse.balloon.open(this.mapInstanse.getCenter(), BalloonCard);
    },
    onMapClick(e) {
      //   if (this.markers.length) return;
      const coords = e.get('coords');
      this.coords = coords;
      //   Закрываем открытый Balloon при клике по карте

      if (this.dblclickTimeout) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
          this.$emit('click', coords);
        }, this.dblclickTimeout);
      } else {
        this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
        this.$emit('click', coords);
      }
    },

    onMapDblClick(e) {
      const coords = e.get('coords');
      //   Закрываем открытый Balloon при клике по карте
      if (this.dblclickTimeout && this.timeout) clearTimeout(this.timeout);
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      this.$emit('dblclick', coords);
    },

    onRightClick(e) {
      this.$emit('onRightClick', e);
    },

    setObserver() {
      //   Вызываем метод fitToViewport если изменились размеры родительского элемента
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
            // console.log('width', entry.contentRect.width);
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
    > * {
      background-color: #fff;
    }
  }
  &__controls {
    display: block !important;
    position: absolute;
    top: 16px;
    right: 16px;
    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }
    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }
  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  .rir-map {
    &__search {
      display: none;
    }
  }
}

// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}
::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  transform: translate(66px, -10px);
  @media(max-width: 491px) {
    bottom: 0;
    transform: unset;
  }
  padding: 0;
  margin: 0;
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
  @media(max-width: 491px) {
    border-radius: 16px 16px 16px 0;
  }
}
::v-deep .fill-rocky path{
  fill: #3D75E4!important;
}
.r-map__legend{
  background: #FFFFFF;
  border-radius: 4px;
  padding: 8px 12px;
  position: absolute;
  left: 16px;
  bottom: 16px;
  .bulb{
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
</style>

<style>
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-family: 'Golos UI';
  font-size: 24px;
  line-height: 28px;
}

/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}

.ymap-container [class*="islands_icon___"] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='36px' viewBox='0 0 24 36' version='1.1'%3e%3cpath d='M12,0 C18.62745,0 24,5.37255 24,12 C24,18.0768 21.377925,22.230675 19.402275,25.36065 C19.264725,25.5786 19.13025,25.791675 18.999975,26.000025 C17.2179,28.851375 12.467025,34.56105 11.45295,35.771325 C11.329125,35.919075 11.149575,36 10.956825,36 C10.4964,36 10.18035,35.535675 10.3395,35.10375 C13.399875,26.799675 12,24 12,24 C5.37255,24 0,18.62745 0,12 C0,5.37255 5.37255,0 12,0 Z M12,18 C15.313725,18 18,15.313725 18,12 C18,8.686275 15.313725,6 12,6 C8.686275,6 6,8.686275 6,12 C6,15.313725 8.686275,18 12,18 Z' id='Shape' fill='%233D75E4'/%3e%3ccircle id='Oval' fill='%23FFFFFF' fill-rule='nonzero' cx='12' cy='12' r='6'/%3e%3c/svg%3e") !important;
  background-size: 24px 36px !important;
  background-repeat: no-repeat;
  background-position: center center;
}

.ymap-container [class*="clusterSmallIcon"],
.ymap-container [class*="clusterMediumIcon"],
.ymap-container [class*="clusterLargeIcon"] {

  [class*="-svg-icon-content"] {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 13px !important;
    text-align: center !important;
    color: #FFFFFF !important;
  }

}

.ymap-container [class*="clusterSmallIcon"] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='46px' height='46px' viewBox='0 0 64 64' version='1.1'%3e%3ctitle%3eMapPinGroup%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='MapPinGroup' fill-rule='nonzero'%3e%3ccircle id='Oval' fill='%23FFFFFF' cx='32' cy='32' r='32'/%3e%3ccircle id='Oval' fill='%233D75E4' cx='32' cy='32' r='26'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
  background-size: 46px 46px !important;

  [class*="-svg-icon-content"] {
    font-size: 20px !important;
  }
}

.ymap-container [class*="clusterMediumIcon"] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='58px' height='58px' viewBox='0 0 64 64' version='1.1'%3e%3ctitle%3eMapPinGroup%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='MapPinGroup' fill-rule='nonzero'%3e%3ccircle id='Oval' fill='%23FFFFFF' cx='32' cy='32' r='32'/%3e%3ccircle id='Oval' fill='%233D75E4' cx='32' cy='32' r='26'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
  background-size: 58px 58px !important;

  [class*="-svg-icon-content"] {
    font-size: 22px !important;
  }
}

.ymap-container [class*="clusterLargeIcon"] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='71px' height='71px' viewBox='0 0 64 64' version='1.1'%3e%3ctitle%3eMapPinGroup%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='MapPinGroup' fill-rule='nonzero'%3e%3ccircle id='Oval' fill='%23FFFFFF' cx='32' cy='32' r='32'/%3e%3ccircle id='Oval' fill='%233D75E4' cx='32' cy='32' r='26'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
  background-size: 71px 71px !important;
}

/*
.ymap-container [class*="pie-chart-content"]{

  font-weight: 800;
  font-size: 24px;
  line-height: 64px;
  text-align: center;
  color: #FFFFFF;
}*/
.ymaps-2-1-79-graphics-SVG {
  stroke-width: 2px;
}
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;

  font-size: 24px;
  line-height: 28px;
}
.cursor-crosshair .ymaps-2-1-79-map ymaps{
  cursor: crosshair!important;
}

.ymaps-2-1-79-pie-chart-content{
  color: #04153E;
  font-family: 'Golos UI';
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}
</style>
